exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-fairs-js": () => import("./../../../src/pages/fairs.js" /* webpackChunkName: "component---src-pages-fairs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-cv-js": () => import("./../../../src/templates/cv.js" /* webpackChunkName: "component---src-templates-cv-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-fair-js": () => import("./../../../src/templates/fair.js" /* webpackChunkName: "component---src-templates-fair-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

